import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import * as Mapper from '../../../helper/Mappers';
import './AirQualityMeasurement.scss';
import { TextFieldComponent, HeadingComponent, ButtonComponent, DatePickerComponent, CheckboxComponent, AutocompleteDropdown, AutocompleteComponent, ListPreviousNext, QrReaderButton } from '../../../components/Reusable/'
import { Grid, TextareaAutosize } from '@mui/material';
import { API } from '../../../helper/ApiHelper';
import { AirQualityMeasurementModelFactory } from './AirQualityMeasurementModel';
import ReplacementParts from '../../ReplacementParts/ReplacementParts';
import MeasurementId from '../../MeasurementId/MeasurementId';
import ImageViewer from '../../ImageViewer/ImageViewer';
import * as Fmt from '../../../helper/Formatters';
import * as ImageBlobConstants from '../../ImageViewer/Constants';
import { OpenGlobalSnackbar, ScrollerToTag, AirQualityMeasurementParticleClass, AirQualityMeasurementGasClass, AirQualityMeasurementOilClass } from '../../../helper/GlobalVariables';
import { ExecutorStore, DirtyStore } from '../../../store';
import { NavigationOption } from '../../../helper/ConstantRepository';

export default function AirQualityMeasurement(props) {
    const { denyEdit } = props;
    const aqUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "air-quality";
    let history = useHistory();
    const { t } = useTranslation();
    const { country, lng, id, cid } = useParams();
    const [mainModel, setMainModel] = useState(AirQualityMeasurementModelFactory(t));
    const [replacementPartList, setReplacementPartList] = useState([]);
    const [executorUsersOptions, setexecutorUsersOptions] = useState([]);

    useEffect(() => {
        Mapper.updateExistingModelFormattingLabels(mainModel, AirQualityMeasurementModelFactory(t), setMainModel);
    }, [t]);

    useEffect(() => {
        if (cid > 0) loadExistingData();
        if (cid == 0) loadCleanPage();
    }, [cid]);

    useEffect(() => {
        let execSub = ExecutorStore.executorSettings.subscribe(executorSettings => {
            if (executorSettings && executorSettings.executorUsers) setexecutorUsersOptions(executorSettings.executorUsers);
        })
        return () => execSub.unsubscribe();
    }, []);

    const loadExistingData = () => {
        API.get(`${aqUrl}/${id}/measurement/${cid}`).then(resp => {
            let mappedModel = Mapper.mapDataToModelValues(resp.aqm, AirQualityMeasurementModelFactory(t));
            setMainModel({ ...mappedModel });
            setReplacementPartList(resp.aqm.replacementPartList);
            ScrollerToTag()
        })
    }

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const loadCleanPage = async () => {
        let guid = Mapper.getNewGuid();
        let newModel = _.cloneDeep(AirQualityMeasurementModelFactory(t));
        newModel.guid.value = guid;
        setMainModel(newModel);
        setReplacementPartList([]);
        scrollTop();
    }

    const copyForm = async () => {
        Mapper.copyForm(mainModel, setMainModel);
        replacementPartList.forEach(x => {
            x.id = 0;
            x.guid = Mapper.getNewGuid();
        });
        scrollTop();
    }

    const clickCancel = () => {
        history.push("/" + country + "/" + lng + '/project/' + id + '/AQ' + "#AirQualityMeasurement");
    }

    const postForm = async (navigationOption) => {
        let isValid = validateFormModel();
        if (isValid === true) {
            let objectPostModel = Mapper.extractValuesFromModel(mainModel);
            objectPostModel.ReplacementPartList = replacementPartList;
            let postModel = {
                ProjectId: id,
                AirQualityMeasurement: objectPostModel
            };

            let method = mainModel.id.value == 0 ? "post" : "put";

            API({
                url: `${aqUrl}/${id}/measurement/${mainModel.id.value}`,
                method,
                data: postModel,
            }).then(async (componentId) => {
                DirtyStore.setIsDirty(false);
                OpenGlobalSnackbar("success", t("SaveSuccessful"));
                switch (navigationOption) {
                    case NavigationOption.Reload:
                        history.push(`/${country}/${lng}/project/${id}/AQ/AirQualityMeasurement/${componentId}`);
                        break;
                    case NavigationOption.Create:
                        if (cid == 0) loadCleanPage();
                        history.push(`/${country}/${lng}/project/${id}/AQ/AirQualityMeasurement/0`);
                        break;
                    case NavigationOption.Copy:
                        await copyForm();
                        break;
                    default:
                        break;
                }
            });
        }
        else {
            OpenGlobalSnackbar("danger", t('PleaseVerifyYourEntries'));
        }
        return isValid;
    }

    const validateFormModel = () => {
        let isValid = true;
        Object.values(mainModel).forEach(m => {
            if (m.validators.length > 0 && m.dependsOn == undefined)
                m.validators.forEach(v => {
                    if (v(m.value) === false) {
                        isValid = false;
                        m.isValid = false;
                    }
                });
            else if (m.validators.length > 0 && m.dependsOn != undefined && mainModel[m.dependsOn] === true)
                m.validators.forEach(v => {
                    if (v(m.value) === false) {
                        isValid = false;
                        m.isValid = false;
                    }
                });
            else if (m.validators.length > 0 && m.dependsOn != undefined && mainModel[m.dependsOn] === false)
                m.value = '';
        });
        setMainModel({ ...mainModel });
        return isValid;
    }

    const updateModel = (property, value) => {
        mainModel[property].value = value;
        mainModel[property].isValid = true;
        if (mainModel[property].validators.length > 0)
            mainModel[property].validators.forEach(v => {
                if (v(value) === false) {
                    mainModel[property].isValid = false;
                }
            });
        setMainModel({ ...mainModel });
        DirtyStore.setIsDirty(postForm);
    }

    const updateAutoCompleteValue = (property, id, dropdownList, targetProperty) => {
        if (id == undefined) {
            mainModel[property].value = "";
            mainModel[targetProperty].value = "";
        } else {
            mainModel[property].value = id.toString();
            mainModel[targetProperty].value = id == 0 ? "" : dropdownList.find(el => el.id == id).value;
        }

        setMainModel({ ...mainModel });
    }

    return (
        <div className="air-quality-measurement">
            <Grid container spacing={3} direction="column">
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item id="#AirQualityMeasurement">
                            <HeadingComponent value={t('AirQualityMeasurement')} size="h6" />
                        </Grid>
                        <Grid item>
                            <ListPreviousNext />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MeasurementId disabled={denyEdit} element={mainModel.measurementId} onChange={e => updateModel("measurementId", e)} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <DatePickerComponent
                                disabled={denyEdit}
                                t={t}
                                model={mainModel.detectedDate}
                                onChange={e => updateModel("detectedDate", e)}
                                maxDate={new Date()}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AutocompleteComponent disabled={denyEdit} options={executorUsersOptions} t={t} model={mainModel.detectedBy} onInputChange={(e, value) => {
                                updateModel("detectedBy", value);
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CheckboxComponent model={mainModel.repairIsDone} onChange={e => {
                        updateModel("repairIsDone", e.target.checked);
                    }} />
                </Grid>
                {
                    mainModel.repairIsDone.value &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <DatePickerComponent
                                        t={t}
                                        model={mainModel.repairDate}
                                        onChange={e => updateModel("repairDate", e)}
                                        maxDate={new Date()}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AutocompleteComponent options={executorUsersOptions} t={t} model={mainModel.repairedBy} onInputChange={(e, value) => {
                                        updateModel("repairedBy", value);
                                    }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextareaAutosize
                                        id='textarea-MEASURMENTEDIT-repairComment'
                                        placeholder={t("Comment")}
                                        width="100%"
                                        minRows="5"
                                        type="text"
                                        value={mainModel.repairComment.value}
                                        onChange={e => updateModel("repairComment", e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }

                <Grid item id="#Location">
                    <HeadingComponent value={t('Location')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.building} onChange={e => {
                                updateModel("building", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.department} onChange={e => {
                                updateModel("department", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={9} md={4}>
                            <TextFieldComponent
                                disabled={denyEdit}
                                t={t}
                                model={mainModel.machine}
                                onChange={e => updateModel("machine", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3} md={2}>
                            <QrReaderButton
                                setCode={code => updateModel("machine", code)}
                                id="air-quality-machine-qr"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} id="#Particle">
                    <HeadingComponent value={t('Particle')} size="h6" />
                </Grid>
                <Grid item xs={12} className='pb-0'>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={6}>
                            {t('Class')}
                        </Grid>
                        <Grid item xs={6}>
                            {t('ParticleGrad')}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={6}>
                            <AutocompleteDropdown
                                disabled={denyEdit}
                                model={mainModel.particleRequiredClassId}
                                label={t('ParticleRequiredClass')}
                                options={AirQualityMeasurementParticleClass}
                                optionName="idValue"
                                optionKey="id"
                                onChange={e => updateAutoCompleteValue("particleRequiredClassId", e?.id, AirQualityMeasurementParticleClass, "particleRequiredPG")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent
                                disabled={denyEdit || mainModel.particleRequiredClassId.value != 0}
                                id='ParticleRequiredPG'
                                t={t}
                                model={{ ...mainModel.particleRequiredPG, value: Fmt.replaceUnitString(mainModel.particleRequiredPG.value, t) }}
                                onChange={e => updateModel("particleRequiredPG", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AutocompleteDropdown
                                disabled={denyEdit}
                                model={mainModel.particleExpectedClassId}
                                label={t('ParticleExpectedClass')}
                                options={AirQualityMeasurementParticleClass}
                                optionName="idValue"
                                optionKey="id"
                                onChange={e => updateAutoCompleteValue("particleExpectedClassId", e?.id, AirQualityMeasurementParticleClass, "particleExpectedPG")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent
                                disabled={denyEdit || mainModel.particleExpectedClassId.value != 0}
                                id='ParticleExpectedPG'
                                t={t}
                                model={{ ...mainModel.particleExpectedPG, value: Fmt.replaceUnitString(mainModel.particleExpectedPG.value, t) }}
                                onChange={e => updateModel("particleExpectedPG", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent disabled={denyEdit} id='ParticleMeasuredClass' t={t} model={mainModel.particleMeasuredClass} onChange={e => {
                                updateModel("particleMeasuredClass", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent disabled={denyEdit} id='ParticleMeasuredPG' t={t} model={mainModel.particleMeasuredPG} onChange={e => {
                                updateModel("particleMeasuredPG", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent disabled={denyEdit} id='ParticleResultClass' t={t} model={mainModel.particleResultClass} onChange={e => {
                                updateModel("particleResultClass", e.target.value)
                            }} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6} id="#Water">
                    <HeadingComponent value={t('Water')} size="h6" />
                </Grid>
                <Grid item xs={12} className='pb-0'>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={6}>
                            {t('Class')}
                        </Grid>
                        <Grid item xs={6}>
                            {t('DewPoint')}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={6}>
                            <AutocompleteDropdown
                                disabled={denyEdit}
                                model={mainModel.gasRequiredClassId}
                                label={t('GasRequiredClass')}
                                options={AirQualityMeasurementGasClass}
                                optionName="idValue"
                                optionKey="id"
                                onChange={e => updateAutoCompleteValue("gasRequiredClassId", e?.id, AirQualityMeasurementGasClass, "gasRequiredPG")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent
                                disabled={denyEdit || mainModel.gasRequiredClassId.value != 0}
                                id='GasRequiredPG'
                                t={t}
                                model={{ ...mainModel.gasRequiredPG, value: Fmt.replaceUnitString(mainModel.gasRequiredPG.value, t) }}
                                onChange={e => updateModel("gasRequiredPG", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AutocompleteDropdown
                                disabled={denyEdit}
                                model={mainModel.gasExpectedClassId}
                                label={t('GasExpectedClass')}
                                options={AirQualityMeasurementGasClass}
                                optionName="idValue"
                                optionKey="id"
                                onChange={e => updateAutoCompleteValue("gasExpectedClassId", e?.id, AirQualityMeasurementGasClass, "gasExpectedPG")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent
                                disabled={denyEdit || mainModel.gasExpectedClassId.value != 0}
                                id='GasExpectedPG'
                                t={t}
                                model={{ ...mainModel.gasExpectedPG, value: Fmt.replaceUnitString(mainModel.gasExpectedPG.value, t) }}
                                onChange={e => updateModel("gasExpectedPG", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent disabled={denyEdit} id='GasMeasuredClass' t={t} model={mainModel.gasMeasuredClass} onChange={e => {
                                updateModel("gasMeasuredClass", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent disabled={denyEdit} id='GasMeasuredPG' t={t} model={mainModel.gasMeasuredPG} onChange={e => {
                                updateModel("gasMeasuredPG", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent disabled={denyEdit} id='GasResultClass' t={t} model={mainModel.gasResultClass} onChange={e => {
                                updateModel("gasResultClass", e.target.value)
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} id="#Oil">
                    <HeadingComponent value={t('Oil')} size="h6" />
                </Grid>
                <Grid item xs={12} className='pb-0'>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={6}>
                            {t('Class')}
                        </Grid>
                        <Grid item xs={6}>
                            {t('OilContent')}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={6}>
                            <AutocompleteDropdown
                                disabled={denyEdit}
                                model={mainModel.oilRequiredClassId}
                                label={t('OilRequiredClass')}
                                options={AirQualityMeasurementOilClass}
                                optionName="idValue"
                                optionKey="id"
                                onChange={e => updateAutoCompleteValue("oilRequiredClassId", e?.id, AirQualityMeasurementOilClass, "oilRequiredPG")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent
                                disabled={denyEdit || mainModel.oilRequiredClassId.value != 0}
                                id='OilRequiredPG'
                                t={t}
                                model={{ ...mainModel.oilRequiredPG, value: Fmt.replaceUnitString(mainModel.oilRequiredPG.value, t) }}
                                onChange={e => updateModel("oilRequiredPG", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AutocompleteDropdown
                                disabled={denyEdit}
                                model={mainModel.oilExpectedClassId}
                                label={t('OilExpectedClass')}
                                options={AirQualityMeasurementOilClass}
                                optionName="idValue"
                                optionKey="id"
                                onChange={e => updateAutoCompleteValue("oilExpectedClassId", e?.id, AirQualityMeasurementOilClass, "oilExpectedPG")}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent
                                disabled={denyEdit || mainModel.oilExpectedClassId.value != 0}
                                id='OilExpectedPG'
                                t={t}
                                model={{ ...mainModel.oilExpectedPG, value: Fmt.replaceUnitString(mainModel.oilExpectedPG.value, t) }}
                                onChange={e => updateModel("oilExpectedPG", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent disabled={denyEdit} id='OilMeasuredClass' t={t} model={mainModel.oilMeasuredClass} onChange={e => {
                                updateModel("oilMeasuredClass", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent disabled={denyEdit} id='OilMeasuredPG' t={t} model={mainModel.oilMeasuredPG} onChange={e => {
                                updateModel("oilMeasuredPG", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldComponent disabled={denyEdit} id='OilResultClass' t={t} model={mainModel.oilResultClass} onChange={e => {
                                updateModel("oilResultClass", e.target.value)
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="aqm-explnationimage">
                    <img
                        src={"/pictures/AQMeasurementExplanationTable.png"}
                        alt=""
                    />
                </Grid>
                <Grid item id="#ReplacementParts">
                    <HeadingComponent value={t('ReplacementParts')} size="h6" />
                </Grid>
                <Grid item>
                    <ReplacementParts
                        setIsDirty={() => DirtyStore.setIsDirty(postForm)}
                        disabled={denyEdit}
                        replacementPartList={replacementPartList}
                        setReplacementPartList={setReplacementPartList}
                    />
                </Grid>
                <Grid item>
                    <HeadingComponent value={t('Comment')} size="h6" />
                </Grid>
                <Grid item>
                    <TextareaAutosize disabled={denyEdit} id='textarea_MEASURMENTEDIT-comment' type="text" value={mainModel.airQualityMeasurementComment.value} minRows="5"
                        onChange={e => {
                            updateModel("airQualityMeasurementComment", e.target.value)
                        }}
                    />
                </Grid>
                <Grid item>
                    {mainModel.guid.value && <ImageViewer disabled={denyEdit} groupId={ImageBlobConstants.AirQualityMeasurementPrefix + mainModel.guid.value} />}
                </Grid>
            </Grid >

            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item><ButtonComponent id='MEASURMENTEDIT-cancel' value={t('Cancel')} color="secondary" onChange={clickCancel}></ButtonComponent></Grid>
                    <Grid item><ButtonComponent id='MEASURMENTEDIT-save' value={t('Save')} color="primary" onChange={() => postForm(NavigationOption.Reload)}></ButtonComponent></Grid>
                    {!denyEdit && <Grid item><ButtonComponent id="MEASURMENTEDIT-SaveAndCopy" value={t('SaveAndCopy')} color="primary" onChange={() => postForm(NavigationOption.Copy)}></ButtonComponent></Grid>}
                    {!denyEdit && <Grid item><ButtonComponent id='MEASURMENTEDIT-saveandnew' value={t('SaveAndNew')} color="primary" onChange={() => postForm(NavigationOption.Create)}></ButtonComponent></Grid>}
                </Grid>
            </Grid>
        </div >
    )
}

