import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { IndividualDryerModelFactory } from './IndividualDryerModel';
import MeasurementId from '../../MeasurementId/MeasurementId';
import ImageViewer from '../../ImageViewer/ImageViewer';
import ReplacementParts from '../../ReplacementParts/ReplacementParts';
import { Grid, TextareaAutosize, TableContainer, Paper, Table, TableBody, TableHead, TableCell, TableRow, FormHelperText, Tooltip, IconButton } from '@mui/material';
import { TextFieldComponent, NumberInputFieldComponent, HeadingComponent, DatePickerComponent, SimpleCheckbox, CheckboxComponent, ButtonComponent, SingleSelectComponent, AutocompleteComponent, ListPreviousNext } from '../../Reusable/';
import { API } from '../../../helper/ApiHelper';
import './IndividualDryer.scss';
import * as Mapper from '../../../helper/Mappers';
import * as ImageBlobConstants from '../../ImageViewer/Constants';
import * as Fmt from '../../../helper/Formatters';
import { IndividualDryerTypes, OpenGlobalSnackbar, ScrollerToTag } from '../../../helper/GlobalVariables';
import { ExecutorStore, DirtyStore } from '../../../store';
import * as InputRestrictions from '../../../helper/InputRestrictions';
import { Search } from '@mui/icons-material';
import DryerSearchDialog from './DryerSearchDialog';
import { NavigationOption } from '../../../helper/ConstantRepository';

export default function IndividualDryer(props) {
    const { denyEdit } = props;
    const aqUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "air-quality";
    const { country, lng, id, cid } = useParams();
    const { t } = useTranslation();
    let history = useHistory();
    const [mainModel, setMainModel] = useState(IndividualDryerModelFactory(t));
    const [replacementPartList, setReplacementPartList] = useState([]);
    const [compressorList, setCompressorList] = useState([]);
    const [assignedCompressorList, setAssignedCompressorsList] = useState([]);
    const [isCompressorSelectedState, setIsCompressorSelectedState] = useState(true);
    const [executorUsersOptions, setexecutorUsersOptions] = useState([]);
    const [dryerSearchOpen, setDryerSearchOpen] = useState(false);

    useEffect(() => {
        Mapper.updateExistingModelFormattingLabels(mainModel, IndividualDryerModelFactory(t), setMainModel);
    }, [t]);

    const updateModel = (property, value) => {
        mainModel[property].value = value;
        mainModel[property].isValid = true;
        if (mainModel[property].validators.length > 0)
            mainModel[property].validators.forEach(v => {
                if (v(value) === false) {
                    mainModel[property].isValid = false;
                }
            });
        setMainModel({ ...mainModel });
        DirtyStore.setIsDirty(postForm);
    }

    const updateSelectModel = (element, value) => {
        mainModel[element].value = IndividualDryerTypes.find(x => x.id == value);
        mainModel[element].isValid = true;
        mainModel[element].validators.map((v) => { if (v(value) === false) mainModel[element].isValid = false; });
        setMainModel({ ...mainModel });
        DirtyStore.setIsDirty(postForm);
    }

    const updateassignedCompressors = (evalue, itemId) => {
        if (evalue) {
            assignedCompressorList.push(itemId);
            setAssignedCompressorsList([...assignedCompressorList]);
            isAnyCompressorSelected(assignedCompressorList);
        }
        else {
            assignedCompressorList.splice(assignedCompressorList.indexOf(itemId), 1);
            setAssignedCompressorsList(old => [...old]);
        }
        DirtyStore.setIsDirty(postForm);
    }

    useEffect(() => {
        if (cid > 0) loadExistingData();
        if (cid == 0) loadCleanPage();
    }, [cid]);

    useEffect(() => {
        let execSub = ExecutorStore.executorSettings.subscribe(executorSettings => {
            if (executorSettings && executorSettings.executorUsers) setexecutorUsersOptions(executorSettings.executorUsers);
        })
        return () => execSub.unsubscribe();
    }, []);

    const loadExistingData = async () => {
        API.get(`${aqUrl}/${id}/dryer/${cid}`).then(resp => {
            let mappedModel = Mapper.mapDataToModelValues(resp.dryer, IndividualDryerModelFactory(t));
            setMainModel({ ...mappedModel });
            setReplacementPartList(resp.dryer.replacementPartList);

            if (resp.compressors != undefined && Array.isArray(resp.compressors) && resp.compressors.length > 0) {
                setCompressorList(Mapper.mergeArrayModels(resp.compressors, resp.compressorCalcs.compressorCalcs));
                let assignedCompressorArray = resp.dryer.assignedCompresorsList.map(el => el.compressor.id);
                setAssignedCompressorsList(assignedCompressorArray);
            }
            ScrollerToTag()
        })
    }

    const loadProjectCompressors = async () => {
        if (compressorList.length === 0)
            API.get(`${aqUrl}/${id}/compressors`).then(resp => {
                setCompressorList(Mapper.mergeArrayModels(resp.compressors, resp.compressorCalcs?.compressorCalcs));
            })
    }

    const loadCleanPage = async () => {
        loadProjectCompressors();
        let guid = Mapper.getNewGuid();
        let newModel = _.cloneDeep(IndividualDryerModelFactory(t));
        newModel.guid.value = guid;
        setMainModel(newModel);
        setAssignedCompressorsList([]);
        setReplacementPartList([]);
        scrollTop();
    }

    const copyForm = async () => {
        Mapper.copyForm(mainModel, setMainModel);
        replacementPartList.forEach(x => {
            x.id = 0;
            x.guid = Mapper.getNewGuid();
        });
        scrollTop();
    }

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const postForm = async (navigationOption) => {
        let isValid = validateFormModel();
        if (isValid === true) {
            let tempAssignedCompressors = [];
            assignedCompressorList.forEach(comprId => {
                let ac = {
                    Id: 0, Compressor: compressorList.find(x => x.id == comprId)
                }
                tempAssignedCompressors.push(ac);
            });

            let objectPostModel = Mapper.extractValuesFromModel(mainModel);
            objectPostModel.replacementPartList = replacementPartList;
            objectPostModel.assignedCompresorsList = tempAssignedCompressors;
            var dataUpload = { ProjectId: id, IndividualDryer: objectPostModel };
            let method = mainModel.id.value == 0 ? "post" : "put";
            await API({
                url: `${aqUrl}/${id}/dryer/${mainModel.id.value}`,
                method,
                data: dataUpload,
            }).then(async (componentId) => {
                DirtyStore.setIsDirty(false);
                OpenGlobalSnackbar("success", t("SaveSuccessful"));
                switch (navigationOption) {
                    case NavigationOption.Reload:
                        history.push(`/${country}/${lng}/project/${id}/AQ/IndividualDryer/${componentId}`);
                        break;
                    case NavigationOption.Create:
                        if (cid == 0) loadCleanPage();
                        history.push(`/${country}/${lng}/project/${id}/AQ/IndividualDryer/0`);
                        break;
                    case NavigationOption.Copy:
                        await copyForm();
                        break;
                    default:
                        break;
                }
            });
        }
        else {
            OpenGlobalSnackbar("danger", t('PleaseVerifyYourEntries'));
        }
        return isValid;
    };

    const validateFormModel = () => {
        let isValid = true;
        Object.values(mainModel).forEach(m => {
            if (m.validators.length > 0 && m.dependsOn == undefined)
                m.validators.forEach(v => {
                    if (v(m.value) === false) {
                        isValid = false;
                        m.isValid = false;
                    }
                });
            else if (m.validators.length > 0 && m.dependsOn != undefined && mainModel[m.dependsOn] === true)
                m.validators.forEach(v => {
                    if (v(m.value) === false) {
                        isValid = false;
                        m.isValid = false;
                    }
                });
            else if (m.validators.length > 0 && m.dependsOn != undefined && mainModel[m.dependsOn] === false)
                m.value = '';
        });
        setMainModel({ ...mainModel });
        if (isAnyCompressorSelected(assignedCompressorList) === false) isValid = false;
        return isValid;
    }

    const isAnyCompressorSelected = (assignedCompressorList) => {
        let isValid = assignedCompressorList.length > 0;
        setIsCompressorSelectedState(isValid);
        return isValid;
    }

    const CheckAssignedCompressor = (compresorId) => { return assignedCompressorList.length > 0 ? assignedCompressorList.includes(compresorId) : false };

    const clickCancel = () => {
        history.push("/" + country + "/" + lng + '/project/' + id + '/AQ' + "#InstalledDryers");
    }

    const importDryerModel = (dryerModel) => {
        mainModel.manufacturer.value = dryerModel.manufacturer;
        mainModel.typeOfDryer.value = dryerModel.typeOfDryer;
        mainModel.modelSerialNumber.value = dryerModel.modelSerialNumber;
        mainModel.flowRangeOfDryer.value = dryerModel.flowRangeOfDryer;
        setMainModel({ ...mainModel });
        setDryerSearchOpen(false);
    }

    return (
        <div className="IndividualDryer">
            <DryerSearchDialog
                open={dryerSearchOpen}
                setOpen={setDryerSearchOpen}
                importDryerModel={importDryerModel}
            />
            <Grid container direction="column" spacing={3}>
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item id="#IndividualDryerInformation">
                            <HeadingComponent value={t('IndividualDryerInformation')} size="h6" />
                        </Grid>
                        <Grid item>
                            <ListPreviousNext />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MeasurementId disabled={denyEdit} element={mainModel.measurementId} onChange={e => updateModel("measurementId", e)} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <DatePickerComponent
                                disabled={denyEdit}
                                t={t}
                                model={mainModel.entryDate}
                                onChange={e => updateModel("entryDate", e)}
                                maxDate={new Date()}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AutocompleteComponent disabled={denyEdit} options={executorUsersOptions} t={t} model={mainModel.detectedBy} onInputChange={(e, value) => {
                                updateModel("detectedBy", value);
                            }} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <CheckboxComponent model={mainModel.repairIsDone} onChange={e => {
                        updateModel("repairIsDone", e.target.checked);
                    }} />
                </Grid>
                {mainModel.repairIsDone.value &&
                    <Grid item xs={12}>
                        <Grid container direction="row" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <DatePickerComponent
                                    t={t}
                                    model={mainModel.repairDate}
                                    onChange={e => updateModel("repairDate", e)}
                                    maxDate={new Date()}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <AutocompleteComponent options={executorUsersOptions} t={t} model={mainModel.repairedBy} onInputChange={(e, value) => {
                                    updateModel("repairedBy", value);
                                }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextareaAutosize
                                    id='textarea-DRYEREDIT-repairComment'
                                    placeholder={t("Comment")}
                                    width="100%"
                                    minRows="5"
                                    type="text"
                                    value={mainModel.repairComment.value}
                                    onChange={e => updateModel("repairComment", e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }

                <Grid item xs={12} id="#Location">
                    <HeadingComponent value={t('Location')} size="h6" />
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.compressorRoomNo} onChange={e => {
                                updateModel("compressorRoomNo", e.target.value)
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container directio="row">
                        <Grid item id="#Details">
                            <HeadingComponent value={t('Details')} size="h6" />
                        </Grid>
                        {!denyEdit &&
                            <Grid item>
                                <Tooltip title={t("FindDryerModel")}>
                                    <IconButton
                                        onClick={() => setDryerSearchOpen(true)}
                                        id="btn_Dryer-Search"
                                        color="inherit"
                                        size="large"
                                    >
                                        <Search />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.manufacturer} onChange={e => {
                                updateModel("manufacturer", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SingleSelectComponent
                                disabled={denyEdit}
                                id="typeofdryer"
                                width={1}
                                listFromDb={IndividualDryerTypes}
                                model={mainModel.typeOfDryer}
                                label={mainModel.typeOfDryer.label}
                                t={t}
                                onChange={e => { updateSelectModel("typeOfDryer", e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.modelSerialNumber} onChange={e => {
                                updateModel("modelSerialNumber", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.year} onChange={e => {
                                updateModel("year", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.flowRangeOfDryer} onChange={e => {
                                updateModel("flowRangeOfDryer", InputRestrictions.Decimal(e.target.value, 2))
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.temperatureOftheDryer} onChange={e => {
                                updateModel("temperatureOftheDryer", InputRestrictions.Decimal(e.target.value, 2))
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} id="#AssignedCompressors">
                    <HeadingComponent value={t('AssignedCompressors')} size="h6" />
                </Grid>
                <Grid item xs={12} md={6} className="dryer-assigned-compressors">
                    <TableContainer component={Paper} >
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow className="table-head-row">
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">{t('Nr')}</TableCell>
                                    <TableCell align="left">{t('ModelSerialNumber')}</TableCell>
                                    <TableCell align="left">{t('ExpectedOutputFreeAirDelivery') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {compressorList != undefined && compressorList.length > 0 &&
                                    compressorList.map((item, index) =>
                                        <TableRow id={'dryer-assigned-compressors' + index} key={'dryer-assigned-compressors' + index} className="table-head-row">
                                            <TableCell align="middle">
                                                <SimpleCheckbox
                                                    disabled={denyEdit}
                                                    id={'dryer-assigned-compressors-checkbox' + index}
                                                    defaultChecked={CheckAssignedCompressor(item.id)}
                                                    key={Math.random()}
                                                    color="default"
                                                    onChange={e => updateassignedCompressors(e.target.checked, item.id)}
                                                />
                                            </TableCell>
                                            <TableCell id={"td_DRYEREDIT-No" + index} align="left">
                                                {item.no}
                                            </TableCell>
                                            <TableCell id={"td_DRYEREDIT-ModelSerialNumber" + index} align="left">
                                                {item.modelSerialNumber}
                                            </TableCell>
                                            <TableCell id={"td_DRYEREDIT-NominalPower" + index} align="left">
                                                {Fmt.round(item.rfadOperatingConditions, country, 0)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {!isCompressorSelectedState && <FormHelperText error className="compressor-selected-error">{t("AtleastOneCompressorShouldBeAssigned")}</FormHelperText>}
                </Grid>
                <Grid item xs={12} id="#ReplacementParts">
                    <HeadingComponent value={t('ReplacementParts')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <ReplacementParts
                        setIsDirty={() => DirtyStore.setIsDirty(postForm)}
                        disabled={denyEdit}
                        replacementPartList={replacementPartList}
                        setReplacementPartList={setReplacementPartList}
                    />
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <HeadingComponent value={t('Comment')} size="h6" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextareaAutosize disabled={denyEdit} id="textarea_DRYEREDIT-comment" type="text" value={mainModel.individualDryerComment.value} minRows="5"
                                onChange={e => updateModel("individualDryerComment", e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            {mainModel.guid.value && <ImageViewer disabled={denyEdit} groupId={ImageBlobConstants.IndividualDryerPrefix + mainModel.guid.value} />}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item>
                            <ButtonComponent id="DRYEREDIT-cancel" value={t('Cancel')} color="secondary" onChange={clickCancel} />
                        </Grid>
                        <Grid item>
                            <ButtonComponent id="DRYEREDIT-save" value={t('Save')} color="primary" onChange={() => postForm(NavigationOption.Reload)} />
                        </Grid>
                        {!denyEdit && <Grid item><ButtonComponent id="DRYEREDIT-SaveAndCopy" value={t('SaveAndCopy')} color="primary" onChange={() => postForm(NavigationOption.Copy)}></ButtonComponent></Grid>}
                        {!denyEdit &&
                            <Grid item>
                                <ButtonComponent id="DRYEREDIT-saveandnew" value={t('SaveAndNew')} color="primary" onChange={() => postForm(NavigationOption.Create)} />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div >
    );
}

